.unset {
  all: unset;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-family: 'Rubik', sans-serif;
}

#root {
  min-width: 1280px;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  font-family: 'Rubik', sans-serif !important;
  font-size: 1.5rem !important;
  -webkit-font-smoothing: antialiased;
  margin-top: 92px !important;
  overflow-y: auto;
}

h2 {
  line-height: 40px;
  font-size: 32px;
}

.react-datepicker {
  font-family: Rubik, sans-serif;
  text-align: center;
  color: black;
  border: 0;
  padding: 16px;
  box-shadow: #03030350 0px 5px 20px 0px;
  border-radius: 12px;
  z-index: 10;
  background-color: white;
  * {
    box-sizing: border-box;
    margin: 0;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding: 0;
}

.react-datepicker__current-month {
  font-weight: normal;
  text-transform: capitalize;
  padding-bottom: 16px;
}
.react-datepicker__navigation-icon {
  top: 12px;
}
.react-datepicker__month {
  margin: 0;
}
.react-datepicker__day {
  width: 49px;
  height: 49px;
  font-size: 16px;
  margin: 0;

  line-height: 49px;
  border-radius: 50%;

  &:hover {
    background-color: #007bff !important;
    border-radius: 12px;
    color: white !important;
  }
}
.react-datepicker__day--disabled {
  color: #e1e1e1;
  &:hover {
    opacity: 50%;
    background-color: #007bff;
  }
}

.react-datepicker__day-name {
  width: 49px;
  height: 49px;
  line-height: 49px;
  font-size: 14px;
  text-transform: capitalize;
  color: #ababab;
}
.react-datepicker__day--in-range {
  color: black;
  border-radius: 0;
  opacity: 0.8;
  background-color: #f3f3f3;
}

.react-datepicker__day--in-selecting-range {
  border-radius: 0;
  color: black !important;
  background-color: #f3f3f3 !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: white;
  color: black !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
  background-color: #007bff !important;
  color: #fff !important;
  border-radius: 12px;
  &:hover {
    opacity: 0.9;
    background-color: #007bff;
    color: #fff;
    border-radius: 12px;
  }
}

.react-datepicker__day--today {
  font-weight: normal;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background-color: #1bc47d;
  }
}
