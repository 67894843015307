.tooltip {
  display: flex;
  padding: 6px 12px 8px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #000;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
