.img {
  width: 104px;
  height: 104px;
  object-fit: cover;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.deleteBtn {
  margin-top: 32px;
  display: flex;
  align-items: center;
  grid-area: 16px;
}

.dropdownFromModal {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.files {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 592px;
  grid-template-columns: repeat(5, 104px);
  gap: 18px;
}

.show {
  margin-bottom: 24px;
}

.loader {
  animation-name: rotation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 104px;
  align-items: center;
  justify-content: center;
  padding: 32px 48px;
  border-width: 1px;
  border-radius: 16px;
  border-color: #e1e1e1;
  border-style: dashed;
  border: 1px dashed var(--simple-gray-day, #e1e1e1);
  background-color: white;
  transition: border 0.3s ease-in-out;
  cursor: pointer;
}

.loadingModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 48px;
}

.delete {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  z-index: 2;

  &:hover path {
    fill: #ababab;
  }
}

.dropdown {
  width: 100%;
  display: flex;
  padding: 32px 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px dashed var(--Simple-Gray-Day, #e1e1e1);
  transition: all 0.2s ease;

  &.error {
    border: 1px dashed var(--Simple-Red-Day, #ec3333);
  }

  &:hover {
    background: var(--Pastel-Blue, #ebf1fc);
    border: 1px dashed var(--Simple-Blue-Day, #337cec);
  }
}

.fromModal {
  width: 656px;
}

.load {
  color: var(--simple-blue-day, #337cec);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.mb {
  color: var(--simple-gray-evening, #ababab);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
