.container {
  /*display: flex;*/
  font-size: 24px;
}
@media screen and (min-width: 375px) and (max-width: 1024px) {
  .container {
    flex-direction: column;
  }
}

.other {
  margin-top: 70px;
  /*flex: 4;*/
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  z-index: 100;
}
.buttons-block {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
}
.buttons-block > * {
  margin-right: 20px;
}
.topbar-icons-second {
  margin-right: 0px;
}
.form-category {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.form-category > * {
  padding-bottom: 10px;
}
.buttons-submit {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.button-category-submit {
  margin-right: 20px;
}
.header-category {
  padding: 30px;
  font-size: 20px;
}
.raised-button-input {
  margin-top: 10px;
}
.categories-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.text-invalid {
  color: red;
  font-size: 1.2rem;
}
.recipe-input {
  margin-bottom: 10px;
}
