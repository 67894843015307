.scrollbar {
  scrollbar-width: 4px;
  scroll-behavior: smooth;
  scrollbar-color: #ababab;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    margin: 10px 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .scrollbar-content {
    padding-right: 8px;
  }
}
