.react-datepicker__tab-loop {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999999;
}

.react-datepicker__triangle {
  display: none;
}
