.jodit-wysiwyg {
  overflow-y: scroll !important;
}

.jodit a {
  color: #007bff !important;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
}
.jodit a:hover,
.jodit a:focus {
  color: #0056b3 !important;
}
.jodit ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.jodit ul ul {
  list-style-type: circle;
}

.jodit-status-bar {
  display: none !important;
}

.jodit ul ul ul {
  list-style-type: square;
}

.jodit ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.jodit ol ol {
  list-style-type: lower-alpha;
}

.jodit ol ol ol {
  list-style-type: lower-roman;
}
.jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal {
  background: linear-gradient(0deg, #f3f3f3, #f3f3f3);
  border-radius: 16px 16px 0px 0px !important;
  border: 1px solid #e1e1e1;
}

.jodit-toolbar-button__button {
  border-radius: 0px 0px 0px 0px;
}

.jodit-toolbar__box {
  border: none;
  padding: 0px;
  margin: 0px;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  background-color: white !important;
}

.jodit-toolbar-button {
  border: none !important;
}

.jodit-toolbar__box:not(:empty):not(:empty) {
  background-color: #f3f3f3;
  border: none;
  border-radius: 16px 16px 0px 0px;
}

.jodit-wysiwyg {
  border-radius: 0px 0px 16px 16px !important;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: 24px !important;
}

.jodit-toolbar-editor-collection_mode_horizontal:after {
  display: none !important;
}

.jodit-ui-separator {
  height: 24px;
  margin-top: 6px;
}
.jodit-placeholder {
  visibility: hidden !important;
}
.jodit-ui-group__bold .jodit-toolbar-button__icon {
  content: url('./svgs/Bold.svg');
}

.jodit-ui-group__italic .jodit-toolbar-button__icon {
  content: url('./svgs/Italic.svg');
}

/* Для кнопки Link */
.jodit-ui-group__link .jodit-toolbar-button__icon {
  content: url('./svgs/Link.svg');
}

.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_ul.jodit-toolbar-button_with-trigger_true.jodit-ui-group__ul
  .jodit-toolbar-button__icon {
  content: url('./svgs/TextDots.svg');
}

.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_ol.jodit-toolbar-button_with-trigger_true.jodit-ui-group__ol
  .jodit-toolbar-button__icon {
  content: url('./svgs/TextNumber.svg');
}

.jodit-ui-group__fontsize .jodit-toolbar-button__icon {
  content: url('./svgs/TextSize.svg');
}

.jodit-ui-group__image .jodit-toolbar-button__icon {
  content: url('./svgs/Camera.svg');
}

.jodit-ui-group__video .jodit-toolbar-button__icon {
  content: url('./svgs/Video.svg');
}

.jodit-toolbar-button__button:hover:not([disabled]) {
  border: none;
}

.jodit-ui-button.jodit-ui-button_size_middle.jodit-ui-button_variant_primary.jodit-ui-button_text-icons_true {
  background-color: #0069d9;
  color: #fff;
}

.jodit-toolbar-button__trigger {
  display: none !important;
}
