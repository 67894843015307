.login {
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.9rem;
  font-weight: normal;
  line-height: 1.9rem;
}
.auth-user {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
