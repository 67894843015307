.select-input:disabled {
  background-color: white;
}

.btn-select {
  svg {
    transform: scale(1.4);
  }
}

.active-option {
  background-color: #ebf1fc;
}

.active-option:hover {
  background-color: #ebf1fc;
}
