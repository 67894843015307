.stories-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.edit-button {
  margin-bottom: 30px;
  /*justify-self: flex-end;*/
}
.img-default {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 20px;
  padding-bottom: 0;
}
