.wrapper {
  margin: 0 auto;
  max-width: 1440px;
  margin-top: 250px;
}
.input-value {
  margin-bottom: 20px !important;
  display: inline-block;
}
.register {
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-auth {
  margin-bottom: 100px;
}
